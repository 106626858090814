<template>
  <footer v-if="large" id="big-footer" class="site-footer">
    <ul>
      <li>E. Hülsdau ©</li>
      <li><router-link to="/impressum">Impressum</router-link></li>
    </ul>
  </footer>
  <footer v-else id="small-footer" class="site-footer">
    <ul>
      <li>E. Hülsdau ©</li>
      <li><a href="https://lisahuelsdau.de//impressum/">Impressum</a></li>
    </ul>
  </footer>
</template>

<script>
export default {
  props: {
    large: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
@use "../styles/variables"as vars;
.site-footer {
  position: relative; //bottom: 0;

  ul {
    li {
      display: inline-block;
    }
  }
}

@media only screen and (max-width: 992px) {
  #big-footer {
    display: none;
  }

  .site-footer {
    background-color: vars.$white;
    padding-top: 2rem;
    width: 100%; // height: 3rem;
    position: absolute;
    //display: none;

    &.visible {
      display: inline-block;
    }

    ul {
      padding: 1rem;
    }

    li {
      padding: 0;
    }

    li:first-of-type {
      float: left;
    }

    li:last-of-type {
      float: right;
    }
  }
}
@media only screen and (min-width: 993px) {
  #small-footer {
    display: none;
  }

  .site-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 8vh;
    font-size: 0.7rem;

    ul {
      padding-left: 1rem;

      li {
        padding-left: 1rem;
      }
    }
  }
}
</style>
