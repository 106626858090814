<template>
  <main id="main" class="site-main gallerie mt8">
    <h1 class="u-hidden@visually">Gallerie</h1>
    <div v-for="painting of paintings" :key="painting._id" class="gallery-item">
      <router-link :to="`/galerie/${painting._id}`" title="Bild größer sehen">
        <SanityImage
          :image="painting.image"
          :alt="painting.alt"
          :fit="'fillmax'"
          :lqip="painting.lqip"
          :width="500"
          :height="getHeight(painting)"
        />
        <FontAwesomeIcon :icon="icon" />
        <p>
          <span>{{ painting.title }}</span
          ><br />
          <span v-if="painting.hight && painting.width"
            >{{ painting.width }}cm x {{ painting.hight }}cm</span
          ><br />
          <span v-if="painting.price">€{{ painting.price }}</span>
        </p>
      </router-link>
    </div>
  </main>
</template>
<script>
import { ref, onMounted } from "vue";
import useSanity from "../use/useSanity";
import SanityImage from "../components/SanityImage.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faExpandArrowsAlt } from "@fortawesome/free-solid-svg-icons";

export default {
  components: { SanityImage, FontAwesomeIcon },
  data: () => {
    return {
      icon: faExpandArrowsAlt,
    };
  },
  setup() {
    const { getPaintings } = useSanity();
    const paintings = ref([]);

    onMounted(async () => {
      paintings.value = await getPaintings();
    });

    return { paintings };
  },
  methods: {
    getHeight(painting) {
      return Math.round(500 * (painting.hight / painting.width));
    },
  },
};
</script>
<style lang="scss" scoped>
@use "../styles/variables" as vars;

.site-main.gallerie {
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  .page-title {
    align-self: normal;
  }
}

.gallerie {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.gallery-item {
  min-height: 100vh;

  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  a:hover,
  a {
    text-decoration: none;
    border: none;
    display: inline-block;
    position: relative;
  }
  svg {
    box-sizing: border-box;
    color: vars.$grey;
    position: absolute;
    bottom: 1.5rem;
    right: 0.5rem;
    border: 1px solid vars.$grey;
    border-radius: 50%;
    padding: 0.375rem;
    height: 2rem;
    width: 2rem;
  }
  p {
    text-align: center;
    color: vars.$dark-grey;
  }
}
</style>
