<template>
  <header class="site-header">
    <div class="header-container">
      <router-link
        to="/"
        class="custom-logo-link"
        rel="home"
        aria-current="page"
        ><img
          width="660"
          height="353"
          src="@/assets/logo.png"
          class="custom-logo"
          alt="Logo"
      /></router-link>
      <button
        class="nav-button hamburger hamburger--spring"
        :class="open ? 'is-active' : ''"
        type="button"
        aria-label="Menu"
        aria-controls="navigation"
        :onClick="toggle"
      >
        <span class="hamburger-box">
          <span class="hamburger-inner"></span>
        </span>
      </button>
    </div>

    <nav class="main-navigation">
      <transition name="fade">
        <ul id="primary-menu" class="nav" v-if="!isMobile || open">
          <li class="menu-item">
            <router-link exact to="/" aria-current="page">Galerie</router-link>
          </li>
          <li class="menu-item">
            <router-link exact to="/ausstellungen">Ausstellungen</router-link>
          </li>
          <li class="menu-item">
            <router-link exact to="/ankuendigungen">Ankündigungen</router-link>
          </li>
          <li class="menu-item">
            <router-link exact to="/vita">Vita</router-link>
          </li>
          <li class="menu-item">
            <router-link exact to="/kontakt">Kontakt</router-link>
          </li>
          <li class="menu-item">
            <router-link exact to="/impressum">Impressum</router-link>
          </li>
        </ul>
      </transition>
    </nav>
  </header>
</template>

<script>
export default {
  data() {
    return {
      open: false,
    };
  },
  methods: {
    toggle() {
      this.open = !this.open;
    },
  },
  computed: {
    isMobile() {
      return window.innerWidth <= 867;
    },
  },
  watch: {
    $route() {
      this.open = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@use "../styles/variables"as vars;

.nav {
  font-size: 1.2rem;
}

@media only screen and (max-width: 992px) {
  // #content {
  //   width: 100vw;
  //   height: calc(100vh - 5rem);
  //   margin-top: 5rem;
  //   margin-bottom: 0;
  // }

  .site-header {
    z-index: 2;
    position: fixed;
    top: 0;
    left: 0;
    background-color: vars.$white;

    .nav {
      height: calc(100vh - 5rem);
      overflow-y: scroll;
    }

    text-align: center;

    .nav li.menu-item {
      @include vars.transition(all, 0.25s, 0s);
      padding: 0;

      a {
        display: inline-block;
        width: 100vw;
        padding: 1.4rem;
        &,
        &:hover,
        &:visited,
        &:focus,
        &:active {
          color: vars.$dark-grey;
        }
      }

      &:hover,
      &:focus {
        background-color: vars.$light-grey;
      }
    }

    .custom-logo {
      position: fixed;
      top: 1rem;
      left: 2rem;
      width: 100px;
    }

    .nav-button {
      position: fixed;
      top: 1rem;
      right: 1rem;
    }

    // .nav.nav-collapsed {
    //   display: none; //opacity: 0;
    // }

    .fade-enter-active,
    .fade-leave-active {
      transition: opacity 0.5s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
      opacity: 0;
    }
  }

  .header-container {
    height: 5rem;
    width: 100vw;
    box-shadow: 0.125rem 0.125rem 0.125rem vars.$light-grey;
  }
}

@media only screen and (max-width: 450px) {
  // #content {
  //   height: calc(100vh - 4rem);
  //   margin-top: 4rem;
  //   margin-bottom: 0;
  // }

  .header-container {
    height: 4rem;

    .nav-button {
      top: 0.5rem;
      right: 0.5rem;
    }

    .custom-logo {
      top: 0.5rem;
      left: 1rem;
      width: 90px;
    }
  }
}
@media only screen and (max-width: 991px) {
  a.router-link-active {
    background-color: vars.$light-grey;
  }
}
@media only screen and (min-width: 992px) {
  .hamburger {
    display: none;
  }

  .site-header {
    position: fixed;
    top: 0;
    left: 0;
    height: 92vh;

    &::after {
      z-index: -1;
      content: "";
      width: inherit;
      position: absolute;
      height: 100vh;
      left: 0;
      top: 0;
      box-shadow: 0.125rem 0 0.125rem vars.$light-grey;
    }
    ul li:last-child {
      display: none;
    }
  }

  .custom-logo {
    width: 192px;
    margin: 2rem 0 4rem 2rem;
    display: block;
  }

  .nav {
    li {
      padding: 0.5rem 2rem;
    }

    a {
      position: relative;
      @include vars.transition(all, 0.2s, 0s);

      &:hover {
        border: none;
      }
    }

    //creating a shadowing effect under navigation links
    a:hover::after,
    a.router-link-active::after {
      display: block;
      content: "";
      width: 100%;
      height: 1px;
      background: black;
      position: absolute;
      bottom: 0;
      left: 0;
      box-shadow: 0.125rem 0.125rem 0.125rem vars.$light-grey;
      @include vars.transition(all, 0.2s, 0s);
    }

    &::after {
      @include vars.transition(all, 0.2s, 0s);
      border: none;
    }
  }
}
</style>
