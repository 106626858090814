<template>
  <div class="site">
    <CookieBanner
      message="Diese Website benutzt Cookies um Ihnen die beste Nutzungserfahrung zu bieten."
      linkLabel="Mehr lesen"
      href="/impressum"
      buttonLabel="Einverstanden"
      target="_self"
    />
    <Header />
    <div id="content" class="site-content" @scroll="onScroll">
      <!-- <NewsletterModal v-model="showModal" /> -->
      <div id="primary" class="content-area">
        <router-view />
      </div>
      <Footer />
    </div>
    <Footer large />
    <p id="to-top" v-if="showScrollBtn">Zum Seitenanfang</p>
  </div>
</template>
<script>
import Footer from "./components/Footer.vue";
import Header from "./components/Header.vue";
// import NewsletterModal from "./components/NewsletterModal.vue";
import CookieBanner from "vue-cookieconsent-component";
export default {
  components: {
    Footer,
    Header,
    // NewsletterModal,
    CookieBanner,
  },
  data() {
    return {
      showModal: false,
      scrolledToBottom: false,
    };
  },
  methods: {
    onScroll({ target: { scrollTop, innerHeight, scrollHeight } }) {
      if (scrollTop + innerHeight >= scrollHeight) {
        this.scrolledToBottom = true;
      } else this.scrolledToBottom = false;
    },
    checkCookie(key) {
      var allCookies = document.cookie;
      var cookieArray = allCookies.split(";");
      for (var i = 0; i < cookieArray.length; i++) {
        var cookie = cookieArray[i];
        while (cookie.charAt(0) == " ") {
          cookie = cookie.substring(1);
        }
        if (cookie.indexOf(key) == 0) {
          return cookie.substring(key.length + 1, cookie.length);
        }
      }
      return false;
    },
  },
  computed: {
    showScrollBtn() {
      let res = false;
      switch (this.$route.path) {
        case "/":
          res = this.scrolledToBottom;
          break;
        case "/ausstellungen":
          res = this.scrolledToBottom;
          break;
        case "/ankuendigungen":
          res = this.scrolledToBottom;
          break;
        default:
          res = false;
          break;
      }
      return res;
    },
  },
  mounted() {
    if (this.checkCookie("visited") !== "true") {
      setTimeout(() => {
        this.showModal = true;
        document.cookie = `visited=true; path=/; max-age=${
          365 * 24 * 60 * 60 * 1000
        }`;
      }, 30000);
    }
  },
};
</script>
<style lang="scss">
@use "node_modules/hamburgers/_sass/hamburgers/hamburgers.scss";
@use "styles/variables" as vars;
@import url("https://fonts.googleapis.com/css?family=Cutive+Mono|Didact+Gothic");

$cookieconstent-compliance-background: #333;
@import "./node_modules/vue-cookieconsent-component/src/scss/cookie-consent";
@import "./node_modules/vue-cookieconsent-component/src/scss/cookie-consent-bottom";
/*** all devices ***/

/* normalising */

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
}

button:focus {
  outline: 0;
}

/* text */

html {
  font-size: 16px;
  font-family: "Didact Gothic", "Century Gothic", sans-serif;
  color: vars.$dark-grey;
  background-color: vars.$white;
}

body {
  display: contents;
}

nav,
.site-footer {
  font-family: "Cutive Mono", "Courier New", monospace;

  a:visited {
    color: vars.$dark-grey;
  }

  a:hover,
  a:active {
    border: none;
  }

  a {
    font-style: normal;
  }
}

h1 {
  font-size: 1.4rem;
  font-weight: normal;
}

h2 {
  font-size: 1.2rem;
  font-weight: normal;
}

a,
.link,
button.link {
  text-decoration: none;
  color: vars.$blue;
  border: none;
  background: none;
  padding: 0;
  text-decoration: underline;
  text-underline-position: under;
  &:hover,
  &:focus,
  &:active,
  &:visited:hover {
    background: none;
    outline: none;
    text-decoration: none;
  }

  &:visited {
    color: vars.$purple;
  }
}

h2 a {
  color: vars.$dark-grey;
  font-style: italic;
  text-decoration: none;
  &:visited {
    color: vars.$dark-grey;
  }
  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

button.link--no-copy {
  user-select: none;
}

button,
.button {
  background-color: #eee;
  border: 1px solid vars.$light-grey;
  color: #333;
  font-size: 1rem;
  padding: 10px 15px;
  cursor: pointer;
  @include vars.transition(all, 0.2s, 0s);
  text-decoration: none;
  &:hover {
    background-color: vars.$light-grey;
  }
}

/* general */

article {
  max-width: 800px;
}

main {
  padding: 3rem 1rem 0;
  min-height: 80vh;

  &.mt8 {
    margin: 2rem auto;
  }
  display: inline-block;
}

@media only screen and (min-width: 993px) {
  #content {
    position: absolute;
    top: 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
  main {
    padding: 0 4rem;

    &.mt8 {
      margin-top: 8rem;
    }
  }
}

.custom-logo {
  height: auto;
}

.skip-link {
  visibility: hidden;
}

h1.entry-title,
h2.entry-title {
  display: inline-block;
  margin: 0;
}

.page-title {
  margin-top: 0;
}

ul {
  list-style: none;
  padding: 0;

  a {
    color: vars.$dark-grey;
    text-decoration: none;
    @include vars.transition(all, 0.2s, 0s);
  }
}

div.entry-content img {
  float: left;
  margin: 0.25rem 1rem 0 0;
}

/*** small devices only ***/

/**XS devices **/

/*** big devices only ***/

@media only screen and (min-width: 992px) {
  .site-footer,
  .site-header {
    width: 16rem;
    text-shadow: 0.125rem 0.125rem 0.125rem vars.$light-grey;
  }

  #content {
    width: calc(100% - 16rem);
    margin-left: 16rem;
    height: 100%;
  }
}

/* To top btn */

#to-top {
  // display: none;
  font-family: "Cutive Mono", "Courier New", monospace;
}

#to-top {
  position: fixed;
  bottom: 0;
  right: 1.5rem;
  cursor: default;
  background-color: vars.$white;
  border-radius: 0.5rem 0.5rem 0 0;
  padding: 0.75rem 0.5rem;
  margin: 0;
  transition: opacity 0.3s 0.3s, background-color 0.2s, transform 0.2s;
  cursor: pointer;
  &:hover,
  &:focus {
    background-color: vars.$light-grey;
    transform: translateY(0.15rem);
  }
  z-index: 1;
}
@media only screen and (max-width: 992px) {
  #to-top {
    border: 1px solid vars.$light-grey;
    border-bottom: none;
    right: 1rem;
    font-family: "Didact Gothic", "Century Gothic", sans-serif;
  }
}

/* Cookie */
.cc-revoke.cc-bottom {
  bottom: 0;
  left: 1rem;
  font-family: "Didact Gothic", "Century Gothic", sans-serif;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}

@media only screen and (min-width: 993px) {
  .cc-revoke.cc-bottom {
    left: 18rem;
  }
}

.u-hidden\@visually:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: static;
  white-space: nowrap;
  width: 1px;
  position: absolute;
  top: 0;
}
</style>
