import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import { createGtm } from "@gtm-support/vue-gtm";

import { VueReCaptcha } from "vue-recaptcha-v3";

import { defineRule, configure } from "vee-validate";
import { required, email, regex } from "@vee-validate/rules";

defineRule("required", required);
defineRule("email", email);
defineRule("regex", regex);

configure({
  generateMessage: (context) => {
    if (context.rule.name === "required")
      return `Das Feld ${context.field} ist erforderlich.`;
    if (context.rule.name === "email")
      return `Das Feld ${context.field} muss eine gültige E-Mail-Adresse sein.`;
    else return `Das Format von ${context.field} ist ungültig.`;
  },
});

const app = createApp(App);
app.use(router);
app.use(
  createGtm({
    id: "GTM-W78R2ZQ", // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
    compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
    nonce: "2726c7f26c", // Will add `nonce` to the script tag
    enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    debug: false, // Whether or not display console logs debugs (optional)
    loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
    vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  })
);

// For more options see below
app.use(VueReCaptcha, {
  siteKey: "6LdLgOYbAAAAAFTNU1i4kTRO1CQxBn7BVrGyXWIC",
  loaderOptions: { autoHideBadge: true },
});

app.directive("lazy", {
  mounted(el) {
    function loadImage() {
      const imageElement =
        el.nodeName === "IMG"
          ? el
          : Array.from(el.children).find((el) => el.nodeName === "IMG");
      if (imageElement) {
        imageElement.addEventListener("load", () => {
          setTimeout(() => imageElement.classList.add("loaded"), 100);
        });
        if (imageElement.src !== imageElement.dataset.url)
          imageElement.src = imageElement.dataset.url;
      }
    }

    function handleIntersect(entries, observer) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          loadImage();
          observer.unobserve(el);
        }
      });
    }

    function createObserver() {
      const options = {
        root: null,
        threshold: "0",
        rootMargin: "0px 0px 500px 0px",
      };
      const observer = new IntersectionObserver(handleIntersect, options);
      observer.observe(el);
    }
    if (window && window.IntersectionObserver) {
      createObserver();
    } else {
      loadImage();
    }
  },
});

app.mount("#app");
