import groq from "groq";
import { reactive } from "vue";
const sanity = require("@sanity/client");
const sanityClient = sanity({
  projectId: "mixt3ynr",
  dataset: "production",
  apiVersion: "2021-08-01", // use current UTC date - see "specifying API version"!
  useCdn: true, // `false` if you want to ensure fresh data
});

import imageUrlBuilder from "@sanity/image-url";

const state = reactive({
  loading: false,
  bio: [],
  paintings: [],
  exhibitions: [],
  posts: [],
});

export default function useSanity() {
  const isPreview = () => window.location.search.includes("preview");

  const client = isPreview()
    ? sanityClient.config({ useCdn: false, withCredentials: true })
    : sanityClient;

  const getBio = async () => {
    const query = groq`
        *[_type == 'bio']| order(_updatedAt desc){
          content
        }
    `;

    if (!isPreview() && state.bio.length) return state.bio;

    const bio = await client.fetch(query);
    state.bio = bio[0].content;
    return state.bio;
  };

  const getPaintings = async () => {
    const query = groq`
        *[_type == 'gallery']|order(_updatedAt desc){
            'paintings': paintings[]->{
              ...@,
              "image": @.image.image,
              "alt": @.image.alt,
              "lqip": @.image.image.asset->metadata.lqip
            }
        }
    `;

    if (state.paintings.length) return state.paintings;
    const gallery = await client.fetch(query);

    state.paintings = gallery[0].paintings;

    return state.paintings;
  };

  const getPainting = async (id) => {
    if (state.paintings.length)
      return state.paintings.find((p) => p._id === id);

    const paintings = await getPaintings();
    return paintings.find((p) => p._id === id);
  };

  const getExhibitions = async () => {
    const query = groq`
        *[_type == 'exhibition']|order(from desc, _updatedAt desc){
            location,
            desc, 
            from, 
            to,
            link,
            _id
        }
    `;

    if (!isPreview() && state.exhibitions.length) return state.exhibitions;

    const exhibitions = await client.fetch(query);

    if (isPreview)
      return exhibitions
        .map((e) => {
          const splitId = e._id.split(".");
          return {
            ...e,
            original: splitId.length === 1 ? splitId[0] : splitId[1],
          };
        })
        .reduce((acc, current) => {
          const x = acc.find((item) => item.original === current.original);
          if (!x) return acc.concat([current]);
          return acc;
        }, []);

    state.exhibitions = exhibitions;

    return state.exhibitions;
  };

  const getPosts = async () => {
    const query = groq`
        *[_type == 'post']|order(publishedAt desc, _updatedAt desc){
            title, 
            content,
            publishedAt,
            "slug": slug.current,
            "excerpt": pt::text(content),
            _id
        }
    `;

    if (!isPreview() && state.posts.length) return state.posts;

    const posts = await client.fetch(query);

    if (isPreview())
      return posts
        .map((e) => {
          const splitId = e._id.split(".");
          return {
            ...e,
            original: splitId.length === 1 ? splitId[0] : splitId[1],
          };
        })
        .reduce((acc, current) => {
          const x = acc.find((item) => item.original === current.original);
          if (!x) return acc.concat([current]);
          return acc;
        }, []);

    state.posts = posts.filter((p) => new Date(p.publishedAt) < new Date());

    return state.posts;
  };

  const getPost = async (slug) => {
    if (!isPreview() && state.posts.length) {
      const post = state.posts.find((p) => p.slug === slug);
      if (post) return post;
    }
    const query = groq`
        *[_type == 'post' && slug.current == $slug]|order(_updatedAt desc){
            title, 
            content,
            publishedAt,
            "slug": slug.current,
            "excerpt": pt::text(content)
        }
    `;
    const post = await client.fetch(query, { slug });
    if (new Date(post.publishedAt) > new Date()) return null;

    return post[0];
  };

  const imageBuilder = imageUrlBuilder(client);
  return {
    getPaintings,
    getPainting,
    getBio,
    getExhibitions,
    getPosts,
    getPost,
    imageBuilder,
  };
}
