<template>
  <img
    v-lazy
    :src="lqip || imageUrl"
    :data-url="imageUrl"
    :srcset="srcset"
    :sizes="sizes"
    :alt="alt"
    :class="['o-image', full && 'o-image--full', className]"
    :height="height"
    :width="width"
  />
</template>

<script>
import useSanity from "../use/useSanity";
// Get a pre-configured url-builder from your sanity client

export default {
  name: "SanityImage",
  props: {
    image: {
      type: Object,
      required: true,
    },
    full: {
      type: Boolean,
      default: false,
    },
    alt: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
    },
    maxWidth: {
      type: Number,
    },
    maxHeight: {
      type: Number,
    },
    height: {
      type: Number,
    },
    auto: {
      default: "format",
      type: String,
    },
    fit: {
      default: "max",
      type: String,
    },
    format: {
      type: String,
      default: "format",
    },
    className: {
      type: String,
    },
    lqip: {
      type: String,
    },
  },
  data() {
    return {
      breakpoint: 480,
    };
  },

  setup(props) {
    const { imageBuilder } = useSanity();

    const imageUrl = imageBuilder
      .image(props.image)
      .auto(props.format)
      .width(props.width)
      .maxWidth(props.maxWidth)
      .maxHeight(props.maxHeight)
      .height(props.height)
      .fit(props.fit)
      .url();

    let srcset = null;

    if (props.width && props.width > props.breakpoint) {
      srcset = `
        ${imageBuilder
          .image(props.image)
          .auto(props.format)
          .width(props.width)
          .height(props.height)
          .fit(props.fit)
          .url()} ${props.width}w,
        ${imageBuilder
          .image(props.image)
          .auto(props.format)
          .width(props.breakpoint)
          .height(props.height * (props.breakpoint / props.width))
          .fit(props.fit)
          .url()} ${props.breakpoint}w
        `;
    }

    let sizes;
    if (props.width && props.width > props.breakpoint) {
      sizes = `(max-width: 700px) ${props.breakpoint}px, ${props.width}px`;
    }

    return { imageUrl, srcset, sizes };
  },
};
</script>
