import { createRouter, createWebHistory } from "vue-router";
import Galerie from "../views/Gallery.vue";

const routes = [
  {
    path: "/",
    name: "Galerie",
    component: Galerie,
  },
  {
    path: "/galerie/:key",
    name: "Malerei",
    component: () =>
      import(/* webpackChunkName: "galerie" */ "../views/Painting.vue"),
  },
  {
    path: "/ausstellungen",
    name: "Ausstellungen",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "exhibitions" */ "../views/Exhibitions.vue"),
  },
  {
    path: "/ankuendigungen/:slug",
    component: () =>
      import(/* webpackChunkName: "posts" */ "../views/Post.vue"),
  },
  {
    path: "/ankuendigungen",
    name: "Ankuendigungen",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "posts" */ "../views/Posts.vue"),
  },

  {
    path: "/vita",
    name: "vita",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/kontakt",
    name: "Kontakt",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/Contact.vue"),
  },
  {
    path: "/impressum",
    name: "Impressum",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "impressum" */ "../views/Impressum.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
